export default (() => {
  // json
  const langJSON = {
    en: {
      Home: 'Home',
      translate1: 'О UCM',
      Contacts: 'Contacts',
      translate2: 'О нас',
      translate3: 'Investing in growth equity globally',
      translate4: 'United Capital Management',
      translate5: 'We invest in growing mid-market companies',
      translate6: 'We focus on the countries of the former Soviet Union',
      translate7: 'We focus on the countries of the former Soviet Union',
      translate8: 'Our fund is backed by prominent anchor investors',
      translate9: 'About United Capital Management',
      translate10:
        'United Capital Management focuses on investments in mid-sized profitable companies looking for capital to accelerate growth, including businesses pursuing digital transformation.',
      translate11:
        'Currently, we focus on companies located in the CIS.  We will also selectively consider international opportunities in companies established by Russian-speaking entrepreneurs.',
      translate12:
        'Our limited partners are among the most notable global family offices, ultra-high-net worth individuals and institutional investors from CIS and the Middle East, which enable us to access capital at scale.',
      translate13: 'Value creation levers',
      translate14:
        'Using basic data skills you can analysis and improve your business indicators with Around',
      translate15:
        'Deep industry insights and enterprise management proficiency',
      translate16:
        'Our team can work collaboratively with the management to identify and execute strategic and operational improvement initiatives.  We can also help management to optimize business processes, improve corporate and legal structure, and apply best corporate governance practices of leading private equity firms.',
      translate17:
        'We possess deep understanding of capital markets across the entire capital spectrum: bank debt, asset backed and project finance, bonds, mezzanine as well as private and public equity.  We can work with the company’s founders and management on creating the optimal capital structure.  In situations when controlling shareholders desire to exit, our experience can also make a significant impact on the value that the shareholders can receive upon the sale.',
      translate18: 'Multi-billion fundraising track record across geographies ',
      translate19: 'Extensive expertise in mid- and large-cap M&A deals',
      translate20:
        'We believe that an increase in equity value can be achieved by a strategic acquisition.  Our contacts in the corporate and banking world provide numerous ideas and opportunities for a transformative acquisition. ',
      translate21: 'Our investment strategy is focused on growth',
      translate22: 'Целевые отрасли',
      translate23: 'Материалы и промышленность',
      translate24: 'Космические технологии',
      translate25: 'Цифровые технологии',
      translate26: 'Электронная коммерция',
      translate27: 'Медицина и здравоохранение',
      translate28:
        'We believe that in the current high-interest rate environment, revenue growth is the most effective way to create shareholder value.  We invest in businesses with characteristics including:',
      translate29:
        'Large addressable market: ability to achieve sufficient scale for an IPO',
      translate30:
        'Strong growth rates: potential to double revenue in the next 24 to 36 months',
      translate31:
        'Recurring revenue model: we prefer business where revenue is based on tangible assets, enforceable contracts or an established customer base',
      translate32:
        'Strong customer diversification and/or customer segmentation; we avoid investing in fragile business models where success depends on a single customer or contract',
      translate33: 'Компания',
      translate34: '&copy; All rights reserved. Made by',
      translate35:
        'United Capital Management focuses on investments in mid-sized profitable companies looking for capital to accelerate growth, including businesses pursuing digital transformation.',
      translate36: 'Энергетический переход',
      translate37: 'Home-footer',
      translate38: 'Контакты',
      translate39: 'Главная',
      translate40: 'Company',
      translate41: 'О компании',
      translate42: 'Contacts',
      translate43: 'We focus on the countries of the former Soviet Union',
      translate44: 'Our fund is backed by prominent anchor investors',
      translate45: 'Мы верим в успех каждого бизнеса',
      translate46: 'Что мы делаем',
      translate47: 'Наша миссия',
      translate48: 'Профессиональный подход',
      translate49: 'Contact us by calling us now',
      translate50: 'Address',
      translate51: 'Телефон',
      translate52: 'Главный офис',
      translate53: 'Зарезервировать телефон',
      translate54: 'График',
      translate55: 'Пн - Чт',
      translate56: 'Пт - Сб',
      translate57: 'Главный офис',
      translate58: 'Поддержка',
      translate59: '',
      translate60: 'Digital technologies including software',
      translate61: 'E-Commerce including logistics',
      translate62: 'Energy Transition',
      translate63: 'Materials and Industrials',
      translate71: 'Medicine and Health',
      translate69: 'Space technologies',
      translate64: '',
      translate65:
        'Companies that are well positioned to benefit from the growth of IT, digital economy and new technologies',
      translate66: 'Companies that participate in import substitution',
      translate67: 'Company that can benefit from administrative resource',
      translate68:
        'Companies that seek to grow their export revenue and presence internationally',
      translate72: '1. Investment criteria',
      translate73: '2. Target Industries',
      translate74: '3. Investment themes:',
      translate75:
        'We seek to partner with business owners and entrepreneurs who desire to take their business to the next level.  As growth capital investors, we often come across promising companies that have great businesses but still lack proper management controls, reporting and governance.  We can help companies based on our own considerable experience.  When appropriate, we can seek additional help from outside consultants, lawyers, and bankers.',
      translate76:
        'Wide international network of investment partners and early access to deals',
      translate77:
        'Our relationships in in the business and financial community in CIS and internationally will enable us to identify potential transactions on an exclusive basis.',
      translate78:
        'Attractive unit economics, with gross margins of at least 30%',
    },
    ru: {
      Home: 'Главная',
      translate1: 'О UCM',
      Contacts: 'Контакты',
      translate2: 'О нас',
      translate3: 'Прямые инвестиции в развивающиеся компании',
      translate4: 'United Capital Management',
      translate5: 'Мы инвестируем в растущие компании среднего размера',
      translate6: 'Мы работаем в странах постсоветского пространства',
      translate7: 'Мы работаем в странах постсоветского пространства',
      translate8: 'Нас поддерживают крупные инвесторы',
      translate9: 'О United Capital Management',
      translate10:
        'Мы инвестируем в быстрорастущие компании, ищущие капитал для ускорения своего роста, особенно в бизнесы, проходящие цифровую трансформацию.',
      translate11:
        'В настоящее время мы сосредотачиваемся на компаниях, расположенных в СНГ. Мы также избирательно рассматриваем инвестиции в международные компании, основанные русскоязычными предпринимателями.',
      translate12:
        'Наш фонд поддерживается хорошо капитализированными якорными инвесторами, включая некоторые из наиболее известных глобальных семейных офисов, и инвестиционных фондов из СНГ и Ближнего Востока. Эта поддержка позволяет нам быстро получать доступ к необходимому капиталу.',
      translate13: 'Наш подход к увеличению стоимости акционерного капитала',
      translate14:
        'Using basic data skills you can analysis and improve your business indicators with Around',
      translate15: 'Глубокое понимание управленческих процессов',
      translate16:
        'Наша команда сотрудничает с компаниями для выявления и внедрения стратегических и оперативных улучшений. Мы помогаем оптимизировать бизнес-процессы, корпоративные структуры и интегрировать лучшие практики управления.',
      translate17:
        'У нас есть глубокое понимание мировых рынков капитала, включая банковские кредиты, финансирование под залог активов, проектное финансирование, облигации, мезонинное финансирование, прямые инвестиции и публичные размещения. Мы тесно сотрудничаем с основателями и руководством компаний для разработки оптимальной структуры капитала и повышения стоимости для акционеров при продаже бизнеса.',
      translate18: 'Многомиллиардный опыт привлечения инвестиций',
      translate19: 'Обширный опыт в сделках M&A среднего и крупного размера',
      translate20:
        'Мы считаем, что стратегические приобретения могут значительно увеличить стоимость акционерного капитала. Наша обширная сеть в корпоративном и банковском секторах предоставляет множество возможностей для интересных сделок.',
      translate21: 'Наша инвестиционная стратегия',
      translate22: 'Целевые отрасли',
      translate23: 'Материалы и промышленность',
      translate24: 'Космические технологии',
      translate25: 'Цифровые технологии',
      translate26: 'Электронная коммерция',
      translate27: 'Медицина и здравоохранение',
      translate28:
        'В условиях высокой процентной ставки мы придаем первостепенное значение росту выручки, как лучшему способу увеличения стоимости акционерного капитала. Мы инвестируем в компании, обладающие следующими характеристиками:',
      translate29:
        'Большой рынок: Способность к значительному масштабированию выручки и прибыли, который потенциально позволяет выйти на IPO.',
      translate30:
        'Значительные темпы роста: Потенциал удвоить выручку в течение 24-36 месяцев.',
      translate31:
        'Модель повторяющейся выручки: Предпочтение компаниям, выручка которых основана на материальных активах, обеспечительных контрактах или установленной клиентской базе.',
      translate32:
        'Диверсификация клиентов: Избежание нестабильных ситуаций, где успех компании зависит от одного клиента или контракта.',
      translate33: 'Компания',
      translate34: '&copy; All rights reserved. Made by',
      translate35:
        'United Capital Management фокусируется на инвестициях в российские компании среднего размера, ищущие возможности дальнейшего роста и приобретения',
      translate36: 'Энергетический переход',
      translate37: 'Home-footer',
      translate38: 'Контакты',
      translate39: 'Главная',
      translate40: 'Компания',
      translate41: 'О компании',
      translate42: 'Контакты',
      translate43: 'Мы работаем в странах постсоветского пространства',
      translate44: 'Наши инвесторы — крупнейшие семейные офисы',
      translate45: 'Мы верим в успех каждого бизнеса',
      translate46: 'Что мы делаем',
      translate47: 'Наша миссия',
      translate48: 'Профессиональный подход',
      translate49: 'Свяжитесь с нами, позвонив нам сейчас',
      translate50: 'Адрес',
      translate51: 'Телефон',
      translate52: 'Главный офис',
      translate53: 'Зарезервировать телефон',
      translate54: 'График',
      translate55: 'Пн - Чт',
      translate56: 'Пт - Сб',
      translate57: 'Главный офис',
      translate58: 'Поддержка',
      translate59: '',
      translate60: 'Цифровые технологии (включая программное обеспечение)\n',
      translate61: 'Электронная коммерция (включая логистику)',
      translate62: 'Переход к новым источникам энергии',
      translate63: 'Материалы и промышленность',
      translate71: 'Медицина и здоровье',
      translate69: 'Космические технологии',
      translate64: '',
      translate65:
        'Компании, которые нацелены на рост в цифровой экономике и новых технологиях',
      translate66: 'Компании, участвующие в импортозамещении.',
      translate67:
        'Организации, которые могут использовать административные ресурсы для достижения роста',
      translate68:
        'Бизнесы, стремящиеся увеличить свои экспортные доходы и присутствие на международной арене.',
      translate72: '1. Критерии инвестирования',
      translate73: '2. Целевые отрасли',
      translate74:
        '3. Инвестиционные темы Мы осуществляем инвестиции в четыре ключевые темы:',
      translate75:
        'Мы ищем быстрорастущие компании, которые уже успешно работают на своих рынках, но нуждаются в финансировании для дальнейшего развития. Такие компании часто требуют улучшения в отчетности и корпоративном управлении, и мы можем использовать наш значительный опыт, чтобы помочь этим бизнесам выйти на новый уровень развития.  При необходимости мы используем помощь внешних консультантов, юристов и банкиров.',
      translate76:
        'Широкая международная сеть инвестиционных и корпоративных партнеров',
      translate77:
        'Наши отношения в бизнес-сообществе и финансовых кругах, как в СНГ, так и за рубежом, позволяют нам находить эксклюзивные инвестиционные возможности.',
      translate78:
        'Привлекательная экономическая модель: Минимальная валовая маржа не менее 30%.',
    },
  }

  // run function after page load :: get/set localstorage value and run function
  document.addEventListener('DOMContentLoaded', function () {
    let appLang = localStorage.getItem('lang')

    // if no language value saved in local-storage, set en as default
    if (appLang === null) {
      localStorage.setItem('lang', 'en') // updaet local-storage

      // fun contentUpdate function with en value
      contentUpdate('en')

      // select radiobutton which has data-value = en
      document.querySelector('[data-value="en"]').checked = true
    } else {
      // fun contentUpdate function with value from local-storage - en, ru..
      contentUpdate(appLang)

      // select radiobutton which has data-value == local storage value
      document.querySelector('[data-value="' + appLang + '"]').checked = true
    }
  })

  const changelangEN = document.querySelector('#lang_en')

  changelangEN.addEventListener('click', () => {
    localStorage.setItem('lang', 'en')
    // fun contentUpdate function with value from onchange="changeLang(value)"
    contentUpdate('en')
  })

  const changelangRU = document.querySelector('#lang_ru')

  changelangRU.addEventListener('click', () => {
    localStorage.setItem('lang', 'ru')
    // fun contentUpdate function with value from onchange="changeLang(value)"
    contentUpdate('ru')
  })

  // content/innerhtml update/assign
  function contentUpdate(cl) {
    // get current langage contents in array
    let currLang =
        Object.entries(langJSON)[Object.keys(langJSON).indexOf(cl)][1],
      // get current language content array length
      langCont = Object.entries(currLang).length
    for (let i = 0; i < langCont; i++) {
      // get selectors which has .langchange classes
      if (document.querySelectorAll('.langchange')[i]) {
        let getSelector = document.querySelectorAll('.langchange')[i],
          // get data-key attribute from .langchange class selectors
          getAttr = getSelector.getAttribute('data-key')
        // assign the data-key value from current language array to the .langchange[data-key] selector
        getSelector.innerHTML = currLang[getAttr]
      }
    }
  }
})()
